<div [formGroup]="form">
    <div class="border-bottom mb-4 pb-2">
        <div class="mb-4">
            <div class="fs-14 fw-600 lh-1 mb-2">Szukaj<span class="text-danger ms-1" *ngIf="required">*</span></div>

            <app-form-autosuggest-input>
                <app-form-address-favourites *ngIf="areFavouriteAddressesAvailable" [formGroup]="form"></app-form-address-favourites>
            </app-form-autosuggest-input>

            <ng-container>
                <app-form-error *ngIf="form.controls.term.touched" [errors]="form.controls.term.errors" />
            </ng-container>
        </div>

        <ng-content select="[preview]"></ng-content>
    </div>

    <div>
        <app-form-address-preview
            *ngIf="form.controls.accuracy.getRawValue() === 'houseNumber' || form.controls.accuracy.getRawValue() === 'point'"
        ></app-form-address-preview>
        <app-form-address-manual *ngIf="form.controls.accuracy.getRawValue() === 'street'"></app-form-address-manual>
    </div>

    <div class="d-flex pt-6">
        <div class="ms-auto" *ngIf="hasGeoposition$ | async; else noGeopositionRef">
            <a href="#" class="tw-text-primary" (click)="onShowMapClick($event)">Pokaż punkt na mapie</a>
        </div>
    </div>
</div>
<!-- <div>
    <div>id: {{ form.getRawValue().id }}</div>
    <div>term: {{ form.getRawValue().term }}</div>
    <div>isPoint: {{ form.getRawValue().isPoint }}</div>
    <div>country: {{ form.getRawValue().country }}</div>
    <div>countryRestricted: {{ form.getRawValue().countryRestricted }}</div>
    <div>city: {{ form.getRawValue().city }}</div>
    <div>street: {{ form.getRawValue().street }}</div>
    <div>houseNumber: {{ form.getRawValue().houseNumber }}</div>
    <div>postalCode: {{ form.getRawValue().postalCode }}</div>
    <div>geoPosition: {{ form.getRawValue().geoPosition | json }}</div>
    <div>accuracy: {{ form.getRawValue().accuracy }}</div>
</div> -->

<ng-template #noGeopositionRef>
    <span class="ms-auto" appIcon="geo-alt" status="missing" color="default" size="6"></span>
</ng-template>
