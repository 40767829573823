import { Component, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { ControlContainer, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, combineLatest, delay, distinctUntilChanged, filter, map, mergeMap, startWith, switchMap, take, tap } from 'rxjs';
import { AddressFormGroup } from '../../models';
import { AutosuggestFacade } from '@app/store/heremaps';
import { CountriesFacade } from '@app/store/countries';
import { AutosuggestItem } from '@app/store/heremaps';
import { AutoComplete } from 'primeng/autocomplete';
import { ShowHide } from '@app/shared/animations';
import { PopupService } from '@app/shared/popup';
import { UserFacade } from '@app/modules';
import { Roles } from '@app/modules/permissions';
import { PopupMapPreviewComponent } from '../popup-map-preview/popup-map-preview.component';
import { ACCURACY_TYPES } from '@app/store/order/enums/accuracy.enum';
import { FormService } from '../../services/form.service';

@Component({
    selector: 'app-form-autosuggest',
    styleUrls: ['./form-autosuggest.component.scss'],
    templateUrl: './form-autosuggest.component.html',
    animations: [ShowHide],
    providers: [FormService],
})
export class FormAutosuggestComponent implements OnInit, OnDestroy {
    private readonly formService = inject(FormService);
    private readonly controlContainer = inject(ControlContainer);
    private readonly autosuggestFacade = inject(AutosuggestFacade);
    private readonly countriesFacade = inject(CountriesFacade);
    private readonly userFacade = inject(UserFacade);
    private readonly popupService = inject(PopupService);

    @ViewChild('autocomplete') autocomplete!: AutoComplete;
    @Input() placeholder?: string;
    @Input() countries!: string[];
    @Input() formControlGroup!: string;

    items$!: Observable<AutosuggestItem[]>;
    loading$!: Observable<boolean>;
    hasGeoposition$!: Observable<boolean>;
    areFavouriteAddressesAvailable = this.userFacade.state.user?.role === Roles.ROLE_PARTNER_EMPLOYEE;

    private readonly subscriptions$ = new Subscription();

    get form() {
        return this.formService.form;
    }

    ngOnInit() {
        this.formService.form = <FormGroup<AddressFormGroup>>(<FormGroup>this.controlContainer.control).get(this.formControlGroup);

        const country = this.form.controls.country.getRawValue();
        if (country !== null && !this.countriesFacade.availableCode3.includes(country)) {
            this.form.controls.countryRestricted.patchValue(false);
        }

        this.items$ = combineLatest([
            this.form.controls.term.valueChanges.pipe(startWith(this.form.controls.term.getRawValue())),
            this.form.controls.countryRestricted.valueChanges.pipe(startWith(this.form.controls.countryRestricted.getRawValue())),
            this.countriesFacade.availableCode3$,
        ]).pipe(switchMap(([term, , countries]) => this.autosuggestFacade.items$(term, this.form.controls.countryRestricted.getRawValue() ? countries : [])));

        this.loading$ = combineLatest([
            this.form.controls.term.valueChanges.pipe(startWith(this.form.controls.term.getRawValue())),
            this.form.controls.countryRestricted.valueChanges.pipe(startWith(this.form.controls.countryRestricted.getRawValue())),
            this.countriesFacade.availableCode3$,
        ]).pipe(switchMap(([term, , countries]) => this.autosuggestFacade.loading$(term, this.form.controls.countryRestricted.getRawValue() ? countries : [])));

        this.hasGeoposition$ = this.form.controls.geoPosition.valueChanges.pipe(
            startWith(true),
            map(() => Boolean(this.form.controls.geoPosition.getRawValue()?.latitude && this.form.controls.geoPosition.getRawValue()?.longitude)),
        );

        this.subscriptions$.add(
            this.form.controls.accuracy.valueChanges
                .pipe(
                    startWith(true),
                    map(() => this.form.controls.accuracy.getRawValue()),
                    tap((value) => {
                        if (value === ACCURACY_TYPES.HOUSE_NUMBER || value === ACCURACY_TYPES.POINT) {
                            this.form.controls.city.disable();
                            this.form.controls.street.disable();
                            this.form.controls.houseNumber.disable();
                        } else {
                            this.form.controls.city.enable();
                            this.form.controls.street.enable();
                            this.form.controls.houseNumber.enable();
                        }
                    }),
                )
                .subscribe(),
        );

        // return;

        this.subscriptions$.add(
            this.form.controls.accuracy.valueChanges
                .pipe(
                    distinctUntilChanged(),
                    filter((value) => value === ACCURACY_TYPES.HOUSE_NUMBER || value === ACCURACY_TYPES.POINT),
                    tap(() => {
                        if (this.form.controls.term.getRawValue() === '') {
                            this.formService.resetByCountry();
                        }
                    }),
                    filter(() => this.form.controls.term.getRawValue() !== ''),
                    mergeMap(() =>
                        combineLatest([
                            this.form.controls.countryRestricted.valueChanges.pipe(startWith(this.form.controls.countryRestricted.getRawValue())),
                            this.countriesFacade.availableCode3$,
                        ]).pipe(take(1)),
                    ),
                    mergeMap(([, countries]) => {
                        this.autosuggestFacade.getAutosuggests(
                            this.form.controls.term.getRawValue(),
                            this.form.controls.countryRestricted.getRawValue() ? countries : [],
                        );
                        return this.autosuggestFacade
                            .items$(this.form.controls.term.getRawValue(), this.form.controls.countryRestricted.getRawValue() ? countries : [])
                            .pipe(take(1));
                    }),
                    filter((items) => items.length > 0),
                    delay(10),
                    tap((item) => {
                        this.formService.onSelect(item[0]);
                    }),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    onShowMapClick(event: Event) {
        event.preventDefault();
        this.popupService.open(PopupMapPreviewComponent, this.form.controls.geoPosition.getRawValue());
    }

    get required(): boolean {
        return this.form.controls.term.hasValidator(Validators.required);
    }
}
