<form [formGroup]="form">
    <div class="row">
        <div class="col-8">
            <div class="mb-5">
                <app-form-input formControlGroup="street" [block]="true" [label]="'BOOKINGS.FORM.street' | translate"></app-form-input>
            </div>
        </div>
        <div class="col-4">
            <div class="mb-5">
                <app-form-input formControlGroup="houseNumber" [block]="true" [label]="'BOOKINGS.FORM.house_number' | translate"></app-form-input>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="mb-5">
                <div class="d-flex flex-nowrap">
                    <div class="flex-grow-1">
                        <app-form-input formControlGroup="city" [block]="true" [label]="'BOOKINGS.FORM.city' | translate"> </app-form-input>
                    </div>
                    <div class="mt-12 ms-5">
                        <a href="#" class="d-block" (click)="onSearchTypeChangeClick($event)">
                            <span
                                appTooltip="Nadpisz nazwę ulicy i numer domu"
                                *ngIf="
                                    form.controls.accuracy.getRawValue() === 'houseNumber' || form.controls.accuracy.getRawValue() === 'point';
                                    else unaccurateIcon
                                "
                                appIcon="lock-fill"
                                color="primary"
                                size="7"
                            ></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="flex-grow-1">
                <app-form-input formControlGroup="postalCode" [block]="true" [label]="'BOOKINGS.FORM.postal_code' | translate"></app-form-input>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="d-flex flex-nowrap">
                <div class="flex-grow-1 fc-country-dropdown">
                    <app-form-dropdown
                        formControlGroup="country"
                        [options]="(countriesList$ | async) || []"
                        [block]="true"
                        [label]="'BOOKINGS.FORM.country' | translate"
                    />
                </div>

                <div class="mt-12 ms-5">
                    <a href="#" class="d-block" (click)="onCountryToggleClick($event)">
                        <span
                            [appTooltip]="'autosuggest.country.list.restricted' | translate: { countries: (countriesNames$ | async)?.join(', ') }"
                            *ngIf="form.controls.countryRestricted.getRawValue() === true; else unlockIcon"
                            appIcon="lock-fill"
                            color="primary"
                            size="7"
                        ></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</form>

<ng-template #unlockIcon>
    <span [appTooltip]="'autosuggest.country.list.unrestricted' | translate" appIcon="unlock-fill" color="light" size="7"></span>
</ng-template>

<ng-template #unaccurateIcon>
    <span appTooltip="Przywróć adres z Wyszukiwarki" class="ms-auto" appIcon="unlock-fill" color="light" size="7"></span>
</ng-template>
